import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/layout"),
    children: [
      //首页
      {
        path: "",
        name: "home",
        component: () => import("@/views/home")
      },
      //缴费记录
      {
        path: "/order",
        name: "order",
        component: () => import("@/views/order")
      },
      //个人中心
      {
        path: "/me",
        name: "me",
        component: () => import("@/views/me")
      }
    ]
  },
  // 建档绑卡
  {
    path: "/file",
    name: "file",
    component: () => import("@/views/file")
  },
  // 预约挂号
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register")
  },
  {
    path: "/registers",
    name: "registers",
    component: () => import("@/views/register/indexs")
  },
  // 医生列表
  {
    path: "/register/list",
    name: "register-list",
    component: () => import("@/views/register/list")
  },
  // 医生列表
  {
    path: "/register/lists",
    name: "register-lists",
    component: () => import("@/views/register/lists")
  },
  // 医生详情
  {
    path: "/register/doctor",
    name: "register-doctor",
    component: () => import("@/views/register/doctor")
  },
  // 类型选择
  {
    path: "/register/type",
    name: "register-type",
    component: () => import("@/views/register/type")
  },
  // 类型选择
  {
    path: "/register/wen",
    name: "register-wen",
    component: () => import("@/views/register/wen")
  },
  // 医生回复
  {
    path: "/register/reply",
    name: "register-reply",
    component: () => import("@/views/register/reply")
  },
  {
    path: "/register/empower",
    name: "register-empower",
    meta: { title: "档案调阅授权" },
    component: () => import("@/views/register/empower")
  },
  //预约挂号详情
  {
    path: "/register/detail",
    name: "register-detail",
    component: () => import("@/views/register/detail")
  },
  //预约挂号支付状态
  {
    path: "/register/pay",
    name: "register-pay",
    component: () => import("@/views/register/pay")
  },
  //预约挂号状态
  {
    path: "/register/status",
    name: "register-status",
    component: () => import("@/views/register/status")
  },
  //预约挂号退费
  {
    path: "/register/refund",
    name: "register-refund",
    component: () => import("@/views/register/refund")
  },
  //医保门诊缴费
  {
    path: "/outpatient/medical",
    name: "outpatient-medical",
    component: () => import("@/views/outpatient/medical")
  },
  //门诊缴费
  {
    path: "/outpatient",
    name: "outpatient",
    component: () => import("@/views/outpatient")
  },
  //门诊缴费详情
  {
    path: "/outpatient/detail",
    name: "outpatient-detail",
    component: () => import("@/views/outpatient/detail")
  },
  //门诊缴费支付状态
  {
    path: "/outpatient/pay",
    name: "outpatient-pay",
    component: () => import("@/views/outpatient/pay")
  },
  //门诊缴费状态
  {
    path: "/outpatient/status",
    name: "outpatient-status",
    component: () => import("@/views/outpatient/status")
  },
  //住院清单
  {
    path: "/inpatient",
    name: "inpatient",
    component: () => import("@/views/inpatient")
  },
  //住院清单列表
  {
    path: "/inpatient/list",
    name: "inpatient-list",
    component: () => import("@/views/inpatient/list")
  },
  //住院清单详情
  {
    path: "/inpatient/detail",
    name: "inpatient-detail",
    component: () => import("@/views/inpatient/detail")
  },
  // 住院按金
  {
    path: "/deposit",
    name: "deposit",
    component: () => import("@/views/deposit")
  },
  //住院按金支付状态
  {
    path: "/deposit/pay",
    name: "deposit-pay",
    component: () => import("@/views/deposit/pay")
  },
  //住院按金状态
  {
    path: "/deposit/status",
    name: "status-pay",
    component: () => import("@/views/deposit/status")
  },
  // 查看报告
  {
    path: "/report",
    name: "report",
    component: () => import("@/views/report")
  },
  //报告列表
  {
    path: "/report/list",
    name: "report-list",
    component: () => import("@/views/report/list")
  },
  //报告详情
  {
    path: "/report/detail",
    name: "report-detail",
    component: () => import("@/views/report/detail")
  },
  //电子发票
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("@/views/invoice")
  },
  //问卷调查
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: () => import("@/views/questionnaire")
  },
  //体检报告
  {
    path: "/exReport",
    name: "exReport",
    component: () => import("@/views/exReport")
  },
  //体检报告列表
  {
    path: "/exReport/list",
    name: "exReport-list",
    component: () => import("@/views/exReport/list")
  },
  //体检报告详情
  {
    path: "/exReport/detail",
    name: "exReport-detail",
    component: () => import("@/views/exReport/detail")
  },
  //体检预约
  {
    path: "/examine",
    name: "examine",
    component: () => import("@/views/examine")
  },
  //体检套餐详情
  {
    path: "/examine/detail",
    name: "examine-detail",
    component: () => import("@/views/examine/detail")
  },
  //调查问卷(核酸检测)
  {
    path: "/examine/questionnaire",
    name: "examine-questionnaire",
    component: () => import("@/views/examine/questionnaire")
  },
  //体检预约下单支付
  {
    path: "/examine/order",
    name: "examine-order",
    component: () => import("@/views/examine/order")
  },
  //体检预约支付状态
  {
    path: "/examine/pay",
    name: "examine-pay",
    component: () => import("@/views/examine/pay")
  },
  //体检预约状态
  {
    path: "/examine/status",
    name: "examine-status",
    component: () => import("@/views/examine/status")
  },
  // 预约挂号订单
  {
    path: "/order/register",
    name: "order-register",
    component: () => import("@/views/order/register")
  },
  //体检预约订单
  {
    path: "/order/examine",
    name: "order-examine",
    component: () => import("@/views/order/examine")
  },
  //团队体检
  {
    path: "/team",
    name: "team",
    component: () => import("@/views/team")
  },
  // 核酸检测
  {
    path: "/covid",
    name: "covid",
    component: () => import("@/views/covid/index")
  },
  //添加团检项目
  {
    path: "/team/add",
    name: "team-add",
    component: () => import("@/views/team/add")
  },
  //团检项目支付下单
  {
    path: "/team/order",
    name: "team-order",
    component: () => import("@/views/team/order")
  },
  //团检支付状态
  {
    path: "/team/pay",
    name: "team-pay",
    component: () => import("@/views/team/pay")
  },
  //团检支付后状态
  {
    path: "/team/status",
    name: "team-status",
    component: () => import("@/views/team/status")
  },
  //门诊缴费记录
  {
    path: "/order/outpatient",
    name: "order-outpatient",
    component: () => import("@/views/order/outpatient")
  },
  //门诊缴费缴费详情
  {
    path: "/order/outpatient/detail",
    name: "order-outpatient-detail",
    component: () => import("@/views/order/outpatient/detail")
  },
  //门诊缴费缴费退费
  {
    path: "/order/outpatient/refund",
    name: "order-outpatient-refund",
    component: () => import("@/views/order/outpatient/refund")
  },
  //住院按金记录
  {
    path: "/order/deposit",
    name: "order-deposit",
    component: () => import("@/views/order/deposit")
  },
  //住院按金记录详情
  {
    path: "/order/deposit/detail",
    name: "order-deposit-detail",
    component: () => import("@/views/order/deposit/detail")
  },
  // 预约挂号记录
  {
    path: "/me/register",
    name: "me-register",
    component: () => import("@/views/me/register")
  },
  //预约挂号记录详情
  {
    path: "/me/register/detail",
    name: "me-register-detail",
    component: () => import("@/views/me/register/detail")
  },
  //体检预约记录
  {
    path: "/me/examine",
    name: "me-examine",
    component: () => import("@/views/me/examine")
  },
  //电子健康码
  {
    path: "/me/nrhc",
    name: "nrhc",
    component: () => import("@/views/me/nrhc")
  },
  //电子健康码信息详情
  {
    path: "/me/nrhc/detail",
    name: "nrhc-detail",
    component: () => import("@/views/me/nrhc/detail")
  },
  // 我的医生
  {
    path: "/me/collection",
    name: "collection",
    component: () => import("@/views/me/collection")
  },
  // 抗原检测
  {
    path: "/antihelion",
    name: "antihelion",
    component: () => import("@/views/covid/antihelion")
  },
  // 停车位查询
  {
    path: "/car",
    name: "car",
    component: () => import("@/views/car/index")
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
  // base: "/"
});

export default router;
